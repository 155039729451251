
import { Component, Vue } from 'vue-property-decorator'
import { ElForm } from 'element-ui/types/form'
import { CarBaseInfo } from '@/types/carManage'
import { ProjectUser } from '@/types/project'

@Component({
  name: 'AddBase'
})
export default class extends Vue {
  private info: CarBaseInfo={
    // 基础信息
    projectId: '',
    typeId: '',
    machineName: '',
    brand: '',
    model: '',
    engineNumber: '',
    driver: '',
    tel: '',
    insuranceStartTime: '',
    insuranceEndTime: '',
    // 附加信息
    driverLicenseNum: '',
    driverLicenseValidity: '',
    quasiDrivingModel: '',
    compulsoryInsuranceOrderNum: '',
    commercialInsuranceOrderNum: '',
    insuranceCompany: '',
    vehicleOwner: '',
    dailyParkingPosition: '',
    oilCardNum: '',
    vehiclePlateColor: '',
    vehicleColor: '',
    passPermissionDate: '',
    displacement: '',
    seatsNum: '',
    passStatus: ''
  }

  private dateArr: string[]= []
  private submitShow = false
  private projectUserList: ProjectUser[] = []

   private rules= {
     projectId: [
       { required: true, message: '请选择项目名称', trigger: 'change' }
     ],
     machineName: [
       { required: true, message: '请输入车牌号', trigger: 'blur' }
     ],
     typeId: [
       { required: true, message: '请选择车辆类型', trigger: 'blur' }
     ]

   }

   get carTypeList () {
     return this.$store.state.carTypeList
   }

   get projectList () {
     return this.$store.state.projectList
   }

   get id () {
     return this.$route.params.id as string || ''
   }

   created () {
     this.id && this.getDetail()
   }

   // 获取项目下车辆所有人信息
   getProjectUserList () {
     this.$axios.get(this.$apis.project.selectProjectUserByList, {
       projectId: this.info.projectId
     }).then((res: { list: ProjectUser[]; total: number }) => {
       this.projectUserList = res.list || []
     })
   }

   getDetail () {
     this.$axios.get(this.$apis.carManage.selectMachineById, { machineId: this.id }).then(res => {
       if (res) {
         this.info = res
         this.dateArr = [res.insuranceStartTime, res.insuranceEndTime]
         this.getProjectUserList()
       }
     })
   }

   dateChange (date: string[]) {
     if (date) {
       this.info.insuranceStartTime = date[0]
       this.info.insuranceEndTime = date[1]
     } else {
       this.info.insuranceStartTime = ''
       this.info.insuranceEndTime = ''
     }
   }

   // 无通行证清空有效期
   passStatusChange (value: string) {
     if (value !== '0') {
       this.info.passPermissionDate = ''
     }
   }

   handleProjectChange () {
     // 清空数据
     this.dateArr = []
     Object.keys(this.info).forEach((item: string) => {
       if (item !== 'projectId' && item !== 'machineId') {
         this.info[item] = ''
       }
     })
     this.getProjectUserList()
   }

   saveForm () {
     (this.$refs.form as ElForm).validate(valid => {
       if (valid) {
         this.submitShow = true
         const urls = this.id ? this.$apis.carManage.updateMachine : this.$apis.carManage.insertMachine
         this.$axios.post(urls, this.info).then(() => {
           this.$message.success('保存成功')
           this.$router.back()
         }).finally(() => {
           this.submitShow = false
         })
       }
     })
   }
}
